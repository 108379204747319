import React, {useState} from 'react';
import RequestPhoneCodeForm from './RequestPhoneCodeForm';
import EnterPhoneCodeForm from './EnterPhoneCodeForm';
import BottomNavbar, {Selections} from '@components/Layout/BottomNavbar';

interface ILoginFormProps {}

const LoginForm: React.FC<ILoginFormProps> = () => {
  const [form, setForm] = useState<'phoneNumber' | 'phoneCode'>('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  return (
    <div className="fixed inset-0 max-w-md mx-auto bg-white flex flex-col justify-center pb-40 paddingHorizontal">
      {form === 'phoneNumber' ? (
        <RequestPhoneCodeForm setForm={setForm} setValue={number => setPhoneNumber(number)} />
      ) : (
        <EnterPhoneCodeForm phoneNumber={phoneNumber} />
      )}

      <BottomNavbar defaultSelection={Selections.Login} />
    </div>
  );
};

export default LoginForm;
