import React, {useEffect} from 'react';
import {useLoginMutation, LoginMutationVariables, GetMeQuery, GetMeDocument} from '@graphql/generated/graphql';
import {Controller, useForm} from 'react-hook-form';
import {useApolloClient} from '@apollo/client';
import {useFeedback} from 'context/feedbackContext';
import {useRouter} from 'next/router';
import {FCM_TOKEN_STRING} from '@lib/firebase';
import {parseCookies, setCookie} from 'nookies';
import {JWT_STRING} from '@lib/apolloClient';
import OtpInput from 'react-otp-input';

interface IEnterPhoneCodeFormProps {
  phoneNumber: string;
}

const EnterPhoneCodeForm: React.FC<IEnterPhoneCodeFormProps> = ({phoneNumber}) => {
  const router = useRouter();
  const client = useApolloClient();
  const outletFriendlyId = router.query.outlet;

  const {enqueueFeedback} = useFeedback();
  const [login] = useLoginMutation();

  const {handleSubmit, watch, control} = useForm<LoginMutationVariables>({
    defaultValues: {
      phoneNumber,
      phoneCode: '',
    },
  });

  const watchField = watch('phoneCode');

  const onSubmit = handleSubmit(async (variables: LoginMutationVariables) => {
    const {
      data: {
        login: {jwt, me, errors},
      },
    } = await login({
      variables: {
        ...variables,
        phoneNumber,
        registrationId: parseCookies()[FCM_TOKEN_STRING],
      },
    });
    if (jwt) {
      setCookie(null, JWT_STRING, jwt, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
      const {name, email} = me;
      const hasDetails = name && email;
      const withDetails = outletFriendlyId ? `/outlets/${outletFriendlyId}/cart` : '/';
      const withoutDetails = outletFriendlyId ? `/account/update-prompt?outlet=${outletFriendlyId}` : '/account/update-prompt';
      router.push(hasDetails ? withDetails : withoutDetails);
      client.writeQuery<GetMeQuery>({
        query: GetMeDocument,
        data: {me},
      });
      client.cache.evict({id: 'ROOT_QUERY', fieldName: 'app'});
      client.cache.gc();
    } else if (errors) {
      enqueueFeedback(errors[0]['detail'], true);
    }
  });

  useEffect(() => {
    if (watchField.length === 4) onSubmit();
  }, [watchField]);

  return (
    <section className="flex flex-1 flex-col items-center my-10 paddingHorizontalSmall">
      <div className="mt-20 mb-10 self-start">
        <h2 className="poppins-bold font-2xl">Enter OTP</h2>
        <p className="poppins-medium text-gray mt-2">{`We've sent an OTP to ${phoneNumber}`}</p>
      </div>
      <form onSubmit={onSubmit}>
        <Controller
          name="phoneCode"
          control={control}
          render={({field: {onChange, value}}) => {
            return (
              <OtpInput
                value={value}
                onChange={onChange}
                numInputs={4}
                shouldAutoFocus
                containerStyle="justify-between mb-4"
                renderSeparator={<span className="mx-3"></span>}
                renderInput={props => (
                  <input {...props} className="orangeUnderlineInput w-12 h-14 caret-none focus:ring-0 focus:outline-none poppins-semibold" />
                )}
              />
            );
          }}
        />
      </form>
    </section>
  );
};

export default EnterPhoneCodeForm;
