import * as React from 'react';
import {GetServerSideProps} from 'next';
import {PageGetMeComp, ssrGetMe} from '@graphql/generated/page';
import {ConsumerAuth} from '@lib/helpers/auth-helpers';
import LoginForm from '@components/Auth/LoginForm';

export const getServerSideProps: GetServerSideProps = async ctx => {
  const {props} = await ssrGetMe.getServerPage({}, ctx);
  return ConsumerAuth(props, null, true);
};

const Login: PageGetMeComp = () => (
  <main>
    <LoginForm />
  </main>
);

export default Login;
