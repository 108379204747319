import React, {useCallback, useEffect, useRef, useState} from 'react';
import Image from 'next/image';
import {useForm} from 'react-hook-form';
import {useFeedback, useGeolocation, useLoader} from 'context';
import {useRequestPhoneCodeMutation, RequestPhoneCodeMutationVariables, useAppMetadataQuery, Country} from '@graphql/generated/graphql';
import {DEFAULT_LOCATIONS, DELIVER_TO_ADDRESS, DELIVER_TO_COUNTRY, DELIVER_TO_LATITUDE, DELIVER_TO_LONGITUDE} from '@lib/helpers/helper';
import {parseCookies} from 'nookies';

import myIP from '@lib/myIP';
import countryFlag from '@lib/countryFlag';
import Spinner from '@components/Layout/Spinner';

interface IRequestPhoneCodeFormProps {
  setForm: React.Dispatch<React.SetStateAction<'phoneNumber' | 'phoneCode'>>;
  setValue: (value: string) => void;
}

const RequestPhoneCodeForm: React.FC<IRequestPhoneCodeFormProps> = ({setForm, setValue}) => {
  const {setShowLoader} = useLoader();
  const {enqueueFeedback} = useFeedback();
  const {setLocation, address: hasCachedLocation} = useGeolocation();
  const {handleSubmit} = useForm<RequestPhoneCodeMutationVariables>();

  const defaultLocationRef = useRef(
    (() => {
      const cookies = parseCookies();
      return {
        country: cookies[DELIVER_TO_COUNTRY],
        address: cookies[DELIVER_TO_ADDRESS],
        lat: cookies[DELIVER_TO_LATITUDE],
        lng: cookies[DELIVER_TO_LONGITUDE],
      };
    })(),
  );

  const [countryData, setCountryData] = useState<Country>();
  const [mobileNumber, setMobileNumber] = useState('');
  const [requestPhoneCode] = useRequestPhoneCodeMutation();

  const {data} = useAppMetadataQuery();
  const operatingCountries = data?.app?.operatingCountries;

  const handleChangeCountry = useCallback(
    (value: string) => {
      const foundCountryData = operatingCountries.find(({country}) => value === country);
      const defaultCountryData = operatingCountries[0];
      const newCountryData = foundCountryData ?? defaultCountryData;
      setCountryData(newCountryData);
      if (value === defaultLocationRef.current.country) {
        const country = defaultLocationRef.current.country;
        const address = defaultLocationRef.current.address;
        const lat = defaultLocationRef.current.lat;
        const lng = defaultLocationRef.current.lng;
        setLocation(country, address, lat, lng);
      } else {
        const country = newCountryData.country;
        const address = DEFAULT_LOCATIONS[country].address;
        const lat = DEFAULT_LOCATIONS[country].lat;
        const lng = DEFAULT_LOCATIONS[country].lng;
        setLocation(country, address, lat, lng);
      }
    },
    [operatingCountries],
  );

  const onSubmit = handleSubmit(async _variables => {
    setShowLoader(true);
    const {
      data: {
        requestPhoneCode: {phoneNumber, errors},
      },
    } = await requestPhoneCode({
      variables: {
        phoneNumber: `+${countryData.code}${mobileNumber}`,
      },
    });
    setShowLoader(false);
    if (phoneNumber) {
      setValue(phoneNumber);
      setForm('phoneCode');
    } else if (errors) {
      enqueueFeedback(errors[0]['detail'], true);
    }
  });

  useEffect(() => {
    if (operatingCountries) {
      const foundCountry = operatingCountries.find(c => c.country === defaultLocationRef.current.country);
      const defaultCountryData = foundCountry ?? operatingCountries[0];
      setCountryData(defaultCountryData);
    }
  }, [operatingCountries]);

  useEffect(() => {
    if (!operatingCountries || hasCachedLocation) {
      return;
    }
    (async () => {
      const {country} = await myIP();
      handleChangeCountry(country);
    })();
  }, [operatingCountries, hasCachedLocation, handleChangeCountry]);

  if (!countryData) {
    return <Spinner />;
  }

  return (
    <section className="flex flex-1 flex-col items-start my-10 paddingHorizontalSmall">
      <img className="h-16 w-auto self-center" src="/logo.svg" alt="coox-logo" />
      <div className="mt-20 mb-10">
        <h2 className="poppins-bold font-2xl">Let's sign you in.</h2>
        <p className="poppins-medium text-gray mt-2">Order your favourite cuisines and tasty treats! Available via delivery, takeaway and dine-in.</p>
      </div>
      <form onSubmit={onSubmit} className="w-full">
        <p className="poppins-semibold mb-2">Enter your mobile number</p>

        <div className="flex flex-row items-center justify-start grayUnderlineInput w-full poppins-regular text-sm">
          <div className="flex flex-row justify-start">
            <div className="relative w-12 h-5 p-1">
              <select
                className="absolute z-0 inset-0 rounded cursor-pointer border-none"
                onChange={e => handleChangeCountry(e.target.value)}
                defaultValue={countryData.country}>
                {operatingCountries.map(({country, code}) => (
                  <option key={country} value={country}>
                    +{code}
                  </option>
                ))}
              </select>
              <div className="relative z-10 -mt-1.5 -ml-1.5 w-14 h-6 rounded bg-white flex items-center gap-x-1 pointer-events-none">
                <Image src={countryFlag(countryData.country)} width={22} height={22} />
                <p>+{countryData.code}</p>
              </div>
            </div>
            <span className="text-gray mx-2">&#124;</span>
          </div>
          <input
            type="tel"
            name="number"
            autoComplete="number"
            placeholder="123456789"
            className="border-none focus:outline-none focus:ring-0 focus:border-none placeholder-gray flex-1 text-sm px-0"
            onChange={e => setMobileNumber(e.target.value)}
            required
          />
        </div>

        <div className="flex justify-center mt-10">
          <button
            type="submit"
            className={`poppins-semibold rounded-full px-20 py-2.5 text-white focus:outline-none ${mobileNumber ? 'bg-primary' : 'bg-lightGray'}`}
            disabled={!mobileNumber}>
            Request OTP
          </button>
        </div>
      </form>
    </section>
  );
};

export default RequestPhoneCodeForm;
